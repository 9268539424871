<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :offset-y="true"
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-if="!isMyLead(lead)">
        <v-btn
          onmousedown="return false;"
          width="100"
          elevation="0"
          style="  border-radius:10px!important;"
          v-if="showPostponedIcon()"
          color="#eee"
          :class="
            formatPosponed?.itsCaduced
              ? 'red--text'
              : formatPosponed?.dueToday
              ? 'green--text'
              : 'blue--text'
          "
        >
          <v-row justify="start" align="center">
            <v-icon>{{
              formatPosponed?.itsCaduced ? "mdi-alert-circle" : "mdi-clock"
            }}</v-icon>
            <small class="button-padding">
              {{ formatPosponed?.text }}
            </small>
          </v-row>
        </v-btn>
      </div>
      <div v-else>
        <v-btn
          elevation="0"
          style="  border-radius:10px!important;"
          width="100"
          v-if="showPostponedIcon()"
          class="white--text"
          :color="
            formatPosponed?.itsCaduced
              ? 'red'
              : formatPosponed?.dueToday
              ? 'green'
              : 'blue'
          "
          v-bind="attrs"
          v-on="on"
        >
          <v-row justify="start" align="center">
            <v-icon class="pr-1">{{
              formatPosponed?.itsCaduced ? "mdi-alert-circle" : "mdi-clock"
            }}</v-icon>
            <small
              v-if="lead.phase === 'finished'"
              :class="'grey--text button-padding'"
            >
              {{ formatPosponed?.text }}
            </small>
            <small v-else>
              {{ formatPosponed?.text }}
            </small>
          </v-row>
        </v-btn>
      </div>
    </template>
    <v-card elevation="0" color="white" v-if="menu">
      <v-list>
        <v-list-item>
          <v-list-item-content
            v-if="formatPosponed?.itsCaduced"
            class="red--text"
          >
            Vencido {{ postponed | dateFormat }}
          </v-list-item-content>
          <v-list-item-content v-else class="blue--text">
            Agendado al {{ postponed | dateFormat }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <p class="caption">Agendar</p>
            <v-layout class="options-postpone-lead">
              <v-radio-group v-model="posponedRadioGroup" class="ma-0">
                <v-radio value="2h" class="ma-1">
                  <template slot="label">
                    <v-chip
                      class="ma-1 option"
                      color="primary"
                      label
                      :outlined="posponedRadioGroup !== '2h'"
                    >
                      2 hrs
                    </v-chip>
                  </template>
                </v-radio>
                <v-radio value="1d" class="ma-1">
                  <template slot="label">
                    <v-chip
                      class="ma-1 option"
                      color="primary"
                      label
                      :outlined="posponedRadioGroup !== '1d'"
                    >
                      1 día
                    </v-chip>
                  </template>
                </v-radio>
                <v-radio value="3d" class="ma-1">
                  <template slot="label">
                    <v-chip
                      class="ma-1 option"
                      color="primary"
                      label
                      :outlined="posponedRadioGroup !== '3d'"
                    >
                      3 días
                    </v-chip>
                  </template>
                </v-radio>
                <v-radio value="1w" class="ma-1">
                  <template slot="label">
                    <v-chip
                      class="ma-1 option"
                      color="primary"
                      label
                      :outlined="posponedRadioGroup !== '1w'"
                    >
                      1 semana
                    </v-chip>
                  </template>
                </v-radio>
                <v-radio value="1m" class="ma-1">
                  <template slot="label">
                    <v-chip
                      class="option"
                      color="primary"
                      label
                      :outlined="posponedRadioGroup !== '1m'"
                    >
                      1 mes
                    </v-chip>
                  </template>
                </v-radio>
                <v-radio value="3m" class="ma-1">
                  <template slot="label">
                    <v-chip
                      class="option"
                      color="primary"
                      label
                      :outlined="posponedRadioGroup !== '3m'"
                    >
                      3 meses
                    </v-chip>
                  </template>
                </v-radio>
                <v-radio value="p" class="ma-1">
                  <template slot="label">
                    <v-chip
                      class="option"
                      color="primary"
                      label
                      :outlined="posponedRadioGroup !== 'p'"
                    >
                      Personalizado
                    </v-chip>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-layout>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <div v-if="posponedRadioGroup === 'p'">
            <div>
              Agendar Personalizado
            </div>
            <!--  <div class="mx-2 grey--text current-date-text">
              {{ "Fecha Actual: ( " + scheduled + " )" }}
            </div> -->
          </div>
        </v-list-item>
        <v-list-item>
          <v-col cols="6">
            <div v-if="posponedRadioGroup === 'p'">
              <v-dialog
                ref="dialog"
                v-model="modalDate"
                :return-value.sync="customDate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div>
                    Fecha
                  </div>
                  <v-text-field
                    v-if="posponedRadioGroup === 'p'"
                    :value="customeDateFormatted"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="customDate"
                  :min="currentDate"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalDate = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(customDate), getItemsTime()"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
          </v-col>
          <v-col cols="3">
            <div v-if="posponedRadioGroup === 'p'">
              <div>
                Hora
              </div>
              <v-select
                v-model="customHour"
                :items="itemsHours"
                :label="customHour?.toString()"
              ></v-select>
            </div>
          </v-col>
          <v-col cols="3">
            <div v-if="posponedRadioGroup === 'p'">
              <div class="grey--text">
                (Formato 24 hrs)
              </div>
              <v-select
                v-model="customMinute"
                :items="itemsMinutes"
                :label="customMinute?.toString()"
              ></v-select>
            </div>
          </v-col>
        </v-list-item>
        <v-list-item>
          <div>
            Comentario
          </div>
        </v-list-item>

        <v-list-item>
          <v-text-field dense label="Comentario" v-model="commentText">
            <template v-slot:prepend>
              <v-icon>mdi-message</v-icon>
            </template>
          </v-text-field>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="menu = false">Cancelar</v-btn>
        <v-btn color="primary" @click="handlePostponeLead()">Agendar</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import { mapActions, mapMutations } from "vuex";

import moment from "moment";
moment.locale("es");

export default {
  props: {
    lead: {
      type: Object,
      default: function() {
        return {};
      }
    },
    postponed: {
      type: String,
      default: ""
    },
    leadId: {
      type: String,
      default: ""
    },
    page: {
      type: String,
      default: ""
    }
  },
  data(vm) {
    return {
      contactId: localStorage.getItem("contact_id"),
      role: localStorage.getItem("user_role"),
      modalDate: false,
      modalTime: false,
      currentDate: new Date().toISOString().substr(0, 10),
      customDate: new Date(this.postponed ? this.postponed : new Date())
        .toISOString()
        .substr(0, 10),
      customHour: moment(this.postponed).format("HH"),

      itemsHours: [],
      customMinute: moment(this.postponed).format("mm"),
      itemsMinutes: [],
      posponedRadioGroup: "1d",
      commentText: "",
      fav: true,
      menu: false,
      message: false,
      hints: true,
      menu1: false,
      menu2: false,
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10))
    };
  },
  filters: {
    dateFormat(date, isFullDate = false) {
      if (!date) return "";
      let format = "DD-MMM-YYYY";
      if (isFullDate) format = "DD-MMM h:mm a";
      let dateParsed = moment(String(date)).format(format);
      return dateParsed;
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    customeDateFormatted: {
      get: function() {
        return this.customDate
          ? moment(this.customDate).format("dddd, MMMM Do YYYY")
          : "";
      },
      set: function(val) {
        val ? moment(val).format("dddd, MMMM Do YYYY") : "";
      }
    },
    currentDateToShow: {
      get: function() {
        return this.customDate
          ? moment(this.customDate).format("dddd, MMMM Do YYYY")
          : "";
      },
      set: function(val) {
        val ? moment(val).format("dddd, MMMM Do YYYY") : "";
      }
    },

    getHours: {
      get: function() {
        return this.postponed ? moment(this.postponed).format("HH") : "";
      },
      set: function(val) {
        val ? moment(val).format("HH") : "";
      }
    },
    getMinutes: {
      get: function() {
        return this.postponed ? moment(this.postponed).format("mm") : "";
      },
      set: function(val) {
        val ? moment(val).format("mm") : "";
      }
    },
    scheduled: {
      get: function() {
        return this.postponed
          ? moment(this.postponed).format("dddd, MMMM Do YYYY")
          : "";
      },
      set: function(val) {
        val ? moment(val).format("dddd, MMMM Do YYYY") : "";
      }
    },
    formatPosponed() {
      return this.getIsPostponed(this.lead.postponed, this.lead);
    }
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
    customDate(val) {
      // const newDate = moment(
      //   val +
      //     "T" +
      //     this.customHour +
      //     ":" +
      //     this.customMinute?.toString() +
      //     ":" +
      //     "00"
      // ).toISOString();
      // this.$emit("updateDate", newDate);
      this.customeDateFormatted = val;
    },
    menu() {
      this.customHour = moment(this.postponed).format("HH");
      this.customMinute = moment(this.postponed).format("mm");
    }
  },
  mounted() {
    this.getItemsTime();
  },
  methods: {
    ...mapActions({
      fetchUpdatelLead: "leads/fetchUpdatelLead",
      fetchActualLead: "leads/fetchActualLead",
      fetchAddComment: "leads/fetchAddComment",
      fetchNotificationComment: "leads/fetchNotificationComment",
      updatePropertiesLead: "leads/updatePropertiesLead"
    }),
    ...mapMutations({
      setLoading: "leads/setLoading"
    }),
    getIsPostponed(data, lead) {
      const postponedt = lead.postponed_total;
      const timeType = postponedt?.time;
      const timeValue = postponedt?.dataTime;
      try {
        let now = moment().local();
        let postponed = moment(
          data.date_at ??
            moment()
              .local()
              .format()
        );
        let difference = moment.duration(postponed.diff(now)).asDays();
        if (timeType === "hh") {
          let min = moment.duration(postponed.diff(now)).asMinutes();
          if (Math.round(Math.abs(timeValue)) <= 0) {
            return {
              itsCaduced: min < 0,
              dueToday: now.isSame(postponed, "day"),
              text:
                this.convertH2M(timeValue) === 0 ||
                this.convertH2M(timeValue) === 1
                  ? "1 min"
                  : `${this.convertH2M(timeValue)} min`
            };
          } else {
            return {
              itsCaduced: min < 0,
              dueToday: now.isSame(postponed, "day"),
              text:
                Math.round(Math.abs(timeValue)) === 1
                  ? "1 hora"
                  : `${Math.round(Math.abs(timeValue))} horas`
            };
          }
        } else if (timeType === "dd") {
          return {
            itsCaduced: difference < 0,
            dueToday: now.isSame(postponed, "day"),
            text:
              Math.round(Math.abs(timeValue)) === 0 ||
              Math.round(Math.abs(timeValue)) === 1
                ? "1 día"
                : `${Math.round(Math.abs(timeValue))} días`
          };
        } else if (timeType === "mm") {
          return {
            itsCaduced: difference < 0,
            dueToday: now.isSame(postponed, "day"),
            text:
              Math.round(Math.abs(timeValue)) === 1
                ? "1 mes"
                : `${Math.round(Math.abs(timeValue))} meses`
          };
        } else {
          return {
            itsCaduced: difference < 0,
            dueToday: now.isSame(postponed, "day"),
            text:
              Math.round(Math.abs(timeValue)) === 1
                ? "1 año"
                : `${Math.round(Math.abs(timeValue))} años`
          };
        }
      } catch (error) {
        return {
          itsCaduced: true,
          dueToday: true,
          text: "1 min"
        };
      }
    },
    isMyLead(lead) {
      if (this.role === "broker") {
        return true;
      } else {
        if (this.role === "admin") {
          if (lead && lead.internal_admin && lead.internal_admin._id) {
            return this.contactId === lead.internal_admin._id;
          } else if (lead && lead.internal_broker && lead.internal_broker._id) {
            return this.contactId === lead.internal_broker._id;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    convertH2M(timeInHour) {
      return Math.floor(timeInHour * 60);
    },
    showPostponedIcon() {
      if (this.phase === "finished" || this.phase === "discarded") {
        return false;
      } else {
        return true;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    // determina si un lead esta atrasado (pospuesto para el dia de hoy o antes)
    // o devuelve false en caso de que este pospuesto para días posteriores

    getDatePostponed() {
      let postponedDate = "";
      switch (this.posponedRadioGroup) {
        case "2h":
          postponedDate = moment()
            .add(2, "hours")
            .add(1, "minutes");
          break;
        case "1d":
          postponedDate = moment().add(1, "days");
          break;
        case "3d":
          postponedDate = moment().add(3, "days");
          break;
        case "1w":
          postponedDate = moment().add(1, "weeks");
          break;
        case "1m":
          postponedDate = moment().add(1, "months");
          break;
        case "3m":
          postponedDate = moment().add(3, "months");
          break;
        case "p":
          postponedDate = moment().add(3, "months");
          break;
        default:
          break;
      }
      return postponedDate;
    },
    handlePostponeLead() {
      if (this.posponedRadioGroup === "p") {
        let updateData = {
          leadId: this.leadId,
          lead: {
            postponed_date: moment(
              this.customDate?.toString() +
                "T" +
                this.customHour +
                ":" +
                this.customMinute?.toString() +
                ":" +
                "00"
            )
          }
        };
        this.sendPostponedLead(updateData);
      } else {
        const datePostponed = this.getDatePostponed();
        let updateData = {
          leadId: this.leadId,
          lead: {
            postponed_date: moment(datePostponed).format()
          }
        };
        this.sendPostponedLead(updateData);
      }
      // editar postpone
      // agregar comentario si lo especifico
      if (this.commentText)
        this.sendAddLeadComment(this.leadId, this.commentText);
    },
    getItemsTime() {
      let hour = parseInt(this.customHour?.toString());
      let minute = parseInt(this.customMinute?.toString());
      if (new Date().getUTCDate() === new Date(this.customDate).getUTCDate()) {
        if (hour < new Date().getHours()) {
          hour = new Date().getHours();
          this.customHour =
            new Date().getHours() < 10
              ? "0" + new Date().getHours().toString()
              : new Date().getHours().toString();
        }
        if (minute < new Date().getMinutes()) {
          minute = new Date().getMinutes();
          this.customMinute =
            new Date().getMinutes() < 10
              ? "0" + new Date().getMinutes().toString()
              : new Date().getMinutes().toString();
        }
        this.itemsHours = [];
        this.itemsMinutes = [];
        for (let i = 1; i <= 24; i++) {
          this.itemsHours.push(i < 10 ? "0" + i.toString() : i.toString());
        }
        for (let j = 1; j < 60; j++) {
          this.itemsMinutes.push(j < 10 ? "0" + j.toString() : j.toString());
        }
      } else {
        this.itemsHours = [];
        this.itemsMinutes = [];
        for (let i = 1; i < 24; i++) {
          this.itemsHours.push(i < 10 ? "0" + i.toString() : i.toString());
        }
        for (let j = 1; j < 60; j++) {
          this.itemsMinutes.push(j < 10 ? "0" + j.toString() : j.toString());
        }
      }
    },
    sendPostponedLead(updateData) {
      let self = this;
      return this.$snotify.async(
        "Posponiendo lead",
        "Actualizando ...",
        () =>
          new Promise((resolve, reject) => {
            this.fetchUpdatelLead(updateData)
              .then(() => {
                resolve({
                  title: "Lead Agendado",
                  body: "El lead se ha agendado correctamente.",
                  config: {
                    closeOnClick: true,
                    titleMaxLength: 30,
                    timeout: 4000
                  }
                });
              })
              .catch(() => {
                reject({
                  title: "Error!!!",
                  body:
                    "Ocurrio un problema al agendar el lead. Intentelo más tarde",
                  config: {
                    closeOnClick: true,
                    timeout: 4000
                  }
                });
              })
              .finally(() => {
                self.clearPanel();
              });
          })
      );
    },
    sendAddLeadComment(leadId, commentText) {
      let self = this;
      this.sendingMessage = true;
      let userRole = localStorage.getItem("user_role");
      let userContactId = localStorage.getItem("contact_id"); // contact id of user
      let commentData = {
        leadId: leadId,
        commentText: commentText,
        userRole: userRole,
        contactId: userContactId
      };
      this.fetchAddComment(commentData)
        .then(() => {
          // obtener solo comentarios actualizados
          // o actualizar lead item VUEX
          // pero el modal se cierra y abre
          self.setActualLead(leadId);
          this.sendNotificationComment(self.leadId, commentText);
        })
        .catch(err => {
          throw err;
        });
    },
    sendNotificationComment(leadId, notificationText) {
      const userToken = localStorage.getItem("token");
      const payload = JSON.parse(atob(userToken.split(".")[1]));
      let zone = this.lead.zones ? this.lead.zones : "";
      let name = this.lead.contact_lead_name;
      let how =
        this.lead.contact && this.lead.contact.source
          ? this.lead.contact.source.name
          : "Indefinido";
      let notificationData = {
        role: payload.role,
        leadId: leadId,
        contactId: payload.contact_id,
        message: `${payload.name}: ${notificationText}`,
        headings: `${name} | ${zone} | ${how}`,
        largeIcon: localStorage.getItem("user_image"),
        zones: this.lead.zones ? [this.lead.zones] : []
      };
      this.fetchNotificationComment(notificationData);
    },
    clearPanel() {
      this.posponedRadioGroup = "1d"; // default
      this.setActualLead(this.leadId);
      this.menu = false;
      this.commentText = "";
    },
    setActualLead(leadId) {
      const self = this;
      this.fetchActualLead(leadId)
        .then(res => {
          const properties = {
            postponed: res.postponed,
            postponed_total: res.postponed_total,
            postponed_result: res.postponed_result
          };
          this.updatePropertiesLead({ properties, _id: leadId });
        })
        .finally(() => {
          setTimeout(() => {
            self.setLoading(false);
          }, 1000);
        });
    }
  }
};
</script>
<style scope>
.current-date-text {
  font-size: 14px;
  font-style: italic;
}
.options-postpone-lead .v-input--selection-controls__input {
  display: none !important;
}
.options-postpone-lead .v-input--radio-group__input {
  display: flex;
  flex-direction: row !important;
}
.button-padding {
  padding-left: 3px;
}
</style>
